<template>
  <article
    class="teaser-product teaser-product--variation _js-product-variation"
    :data-variation-id="product.key"
    :data-variation="trackingAsString"
  >
    <a
      :href="product.link"
      class="teaser-product__link-image"
      tabindex="0"
      :aria-label="`${product.brand} ${product.title}`"
      @click="clickLink"
      @click.middle="middleClickLink"
      @click.right="rightClickLink"
    >
      <h3 class="sr-only">{{ product.brand }} {{ product.title }}</h3>
      <div
        v-if="product.isNews || product.isInStockOnWeb"
        class="teaser-product__not-available"
      >
        {{ $t("soldOut") }}
      </div>
      <div class="teaser-product__photos">
        <div class="teaser-product__photo">
          <ResponsiveImage
            v-if="productImage"
            width="642"
            height="856"
            img-class="teaser-product__image"
            :src="fallbackImage"
            :sources="imageSources"
          />
        </div>
        <div
          v-if="hoverColor && hoverColor.fallbackImage"
          class="teaser-product__photo teaser-product__photo--variant"
        >
          <ResponsiveImage
            width="642"
            height="856"
            img-class="teaser-product__image"
            :src="hoverColor.fallbackImage"
            :sources="hoverColor.imageSources"
            :lazy="false"
          />
        </div>
        <template v-if="promotion">
          <span :class="`label ${promotion.className}`">{{
            promotion.text
          }}</span>
        </template>
      </div>
    </a>
    <div class="teaser-product__content">
      <!-- Favorite button -->
      <div
        v-if="!isFavoriteFormHidden && epiProductCode"
        class="teaser-product__lovebutton"
      >
        <button
          type="button"
          class="btn btn--small btn--transparent love"
          :class="{
            'is-loved': isFavorite,
          }"
          :disabled="isLoadingFavorites"
          aria-hidden="true"
          :title="$tc(title)"
          :aria-expanded="!isUserAuthenticated ? isLoginActive : undefined"
          :aria-controls="!isUserAuthenticated ? 'login' : undefined"
          @click="clickFavorite()"
        >
          <Icon :type="IconTypes.Heart" :size="IconSizes.Tiny" />
        </button>
      </div>
       <!-- Colors -->
       <div v-if="product.custom.RGBColorCode" class="teaser-product__colors">
        <a
          v-for="color in colors"
          :key="color.rgb"
          :href="color.url"
          :class="[
            `item-color`,
            color.isSelf ? `self` : null,
            color.meta ? `item-color--${color.meta}` : null,
          ]"
          :style="{ 'background-color': `rgb(${color.rgb})` }"
          :aria-label="`${color.name} (${color.meta}) ${product.title}`"
          @mouseover="mouseOverColor(color)"
          @mouseout="mouseOutColor()"
        ></a>
      </div>
      <!-- Brand -->
      <div class="teaser-product__brand">
        {{ product.brand }}
      </div>
      <!-- Sizes -->
      <template v-if="isInStock">
        <div v-if="sizes" class="teaser-product__sizes">
          <template v-if="isMatrixSize">
            <span>{{ $t("availableInManySizes") }}</span>
          </template>
          <template v-else>
            <span
              v-for="size in sizes"
              :key="size.label"
              :class="{
                'teaser-product__sizes__outofstock': !size.available,
              }"
              >{{ formatSize(size.label) }}
            </span>
          </template>
        </div>
      </template>
      <template v-else>
        <div class="teaser-product__sizes">
          <span>{{ $t("onlyInStores") }}</span>
        </div>
      </template>
      <!-- Highlight text (not sure if used) -->
      <div
        v-if="product.custom.HighlightText"
        class="teaser-product__highlight-text"
      >
        {{ product.custom.HighlightText[0].label }}
      </div>
      <!-- Name -->
      <div class="teaser-product__name">
        <a
          :href="product.link"
          aria-hidden="true"
          @click="clickLink"
          @click.middle="middleClickLink"
          @click.right="rightClickLink"
        >
          {{ product.title }}
        </a>
      </div>
      <!-- Price -->
      <div class="teaser-product__price">
        <div v-if="price" class="price">
          {{ price }}
        </div>
        <div v-if="newPrice" class="price price--new">
          {{ newPrice }}
        </div>
        <div v-if="oldPrice" class="price price--old">
          {{ $t("priceBefore") }} {{ oldPrice }}
        </div>
      </div>
      <!-- Member promotion -->
      <div v-if="product.custom.MemberPromotion" class="teaser-product__members-promotions-text">
          {{ product.custom.MemberPromotion[0].label }}
      </div>
     
    </div>
  </article>
</template>

<script setup lang="ts">
import { notifyClick } from "../../mixins/notifyClick";
import { getProductClickEvent, pushDataToGtm } from "../../mixins/tracking";
import {
  ProductVariant,
  Product,
  ProductImage,
  ProductImageSource,
  ProductGroup,
} from "../../services/search.service";
import Icon from "../../components/icon/icon.vue";
import { IconSizes, IconTypes } from "../../components/icon/icon.model";
import ResponsiveImage from "../../components/responsiveImage/responsiveImage.vue";
import { computePromotion } from "../../mixins/productBadge.mixin";
import { formatSize } from "../../mixins/productSize.mixin";
import {
  computeNewPrice,
  computeOldPrice,
  computePrice,
  computeRebate,
  priceAsNumber,
} from "../../mixins/productPrice.mixin";
import { getCustomApptusValue } from "../../utils/search.utils";
import { computed, ref, onUnmounted } from "vue";

const props = defineProps<{
  userId: string;
  productGroup: ProductGroup;
  product: Product;
  isFavoriteFormHidden: boolean;
  isUserAuthenticated: boolean;
  favorites?: null | string[];
  isLoadingFavorites: boolean;
  useGtmTracking: boolean;
}>();

const emit = defineEmits<{
  (e: "toggleFavorite", epiProductCode: string): void;
}>();

const isLoginActive = ref(false);
const hoverColor = ref<HoverColor>();

const isFavorite = computed(() => {
  if (!props.favorites) {
    return false;
  }
  if (!epiProductCode.value) {
    return false;
  }

  return props.favorites.indexOf(epiProductCode.value) !== -1;
});

const newPrice = computed(() => {
  return computeNewPrice(props.product);
});

const oldPrice = computed(() => {
  return computeOldPrice(props.product);
});

const price = computed(() => {
  return computePrice(props.product);
});

const rebate = computed(() => {
  return computeRebate(props.product);
});

const promotion = computed(() => {
  return computePromotion(props.product);
});

const title = computed(() => {
  return isFavorite.value ? "removeFavorite" : "addFavorite";
});

const epiProductCode = computed((): string => {
  return props.product.custom.EpiPoductCode[0].label;
});

type Color = {
  isSelf: boolean;
  rgb: string;
  name: string;
  url: string;
  meta: string;
  imageAlt: string;
  fallbackImage: string;
  imageSources: Array<{
    srcset: string;
    media: string;
    type: string;
  }>;
};
type HoverColor = Color | null;

const colors = computed((): Color[] => {
  const colorList: Color[] = [];
  const products = props.productGroup.products
    .filter((product: Product) => {
      return product.custom.RGBColorCode && product.custom.RGBColorCode[0];
    })
    .filter((product: Product) => {
      return product.custom.ColorName && product.custom.ColorName[0];
    });

  products.forEach((product: Product) => {
    const productImage =
      product.imageInfo &&
      product.imageInfo.images &&
      product.imageInfo.images[0];

    colorList.push({
      isSelf: product === props.product,
      rgb: product.custom.RGBColorCode[0].label,
      name: product.custom.ColorName[0].label,
      url: product.link,
      meta: (
        (product.custom.MetaColor &&
          product.custom.MetaColor[0] &&
          product.custom.MetaColor[0].label) ||
        ""
      ).toLowerCase(),
      imageAlt: productImage ? productImage.alt : "",
      fallbackImage: productImage ? getFallbackImage(productImage) : "",
      imageSources: productImage ? getImageSources(productImage) : [],
    });
  });
  return colorList;
});

const isMatrixSize = computed((): boolean => {
  return (
    props.product.custom &&
    props.product.custom.IsMatrixSize &&
    props.product.custom.IsMatrixSize[0] &&
    props.product.custom.IsMatrixSize[0].label === "True"
  );
});

const metaColor = computed((): string => {
  const product = props.product as Product;

  return (
    product.custom &&
    product.custom.MetaColor &&
    product.custom.MetaColor[0] &&
    product.custom.MetaColor[0].label
  );
});

const department = computed((): string => {
  const product = props.product as Product;

  return (
    product.custom &&
    product.custom.Dept &&
    product.custom.Dept[0] &&
    product.custom.Dept[0].label
  );
});

const category = computed((): string => {
  const product = props.product as Product;

  return getCustomApptusValue(product, "Category");
});

const gtmStockStatus = computed((): string => {
  const product = props.product as Product;

  return (
    product.custom &&
    product.custom.GtmStockStatus &&
    product.custom.GtmStockStatus[0] &&
    product.custom.GtmStockStatus[0].label
  );
});

const isInStock = computed((): boolean => {
  return (props.product as Product).inStock;
});

const variantsInStock = computed((): ProductVariant[] => {
  const product = props.product as Product;

  return (product.variants || []).filter((variant: ProductVariant) => {
    return !!variant.inStock;
  });
});

const sizes = computed(
  (): Array<{
    label: string;
    available: boolean;
  }> => {
    return (variantsInStock.value || [])
      .filter((variant: ProductVariant) => {
        return !!variant.size;
      })
      .map((variant: ProductVariant) => {
        return {
          label: variant.size as string,
          available: true,
        };
      });
  },
);

const productImage = computed((): ProductImage | undefined => {
  if (props.product.imageInfo && props.product.imageInfo.images) {
    return props.product.imageInfo.images[0];
  }
  return undefined;
});

const fallbackImage = computed((): string => {
  if (!productImage.value) {
    return "";
  } else {
    return getFallbackImage(productImage.value);
  }
});

const imageSources = computed(
  ():
    | Array<{
        srcset: string;
        media: string;
        type: string;
      }>
    | undefined => {
    if (!productImage.value) {
      return;
    }

    return getImageSources(productImage.value);
  },
);

const tracking = computed((): object | undefined => {
  if (!props.useGtmTracking) {
    return undefined;
  }

  const tracking = getProductClickEvent({
    userId: props.userId || "",
    department: department.value,
    brand: props.product.brand,
    category: category.value,
    rebate: rebate.value,
    stock: gtmStockStatus.value,
    price: priceAsNumber(props.product),
    metaColor: metaColor.value,
    key: props.product.key,
    title: props.product.title,
  });

  return tracking;
});

const trackingAsString = computed((): string | undefined => {
  if (!props.useGtmTracking) {
    return undefined;
  }

  return JSON.stringify(tracking.value);
});

onUnmounted(() => {
  const loginModalEl = document.getElementById("login");

  if (loginModalEl) {
    loginModalEl.removeEventListener("elementToggled", hideLogin);
  }
});

/**
 * Click link
 */
function clickLink($event: Event) {
  notifyClick($event, props.product);

  if (props.useGtmTracking) {
    pushDataToGtm(tracking);
  }
}
/**
 * Middle click link
 */
function middleClickLink($event: Event) {
  notifyClick($event, props.product);
}
/**
 * Right click link
 */
function rightClickLink($event: Event) {
  notifyClick($event, props.product);
}
/**
 * Click favorite
 */
function clickFavorite(): void {
  if (props.isUserAuthenticated) {
    return toggleFavorite();
  }
  if (props.isLoadingFavorites) {
    return;
  }
  displayLogin();
}
/**
 * Display login
 */
function displayLogin() {
  const loginModalEl = document.getElementById("login");

  if (loginModalEl) {
    loginModalEl.classList.toggle("is-active");

    isLoginActive.value = true;

    loginModalEl.addEventListener("elementToggled", hideLogin);
  }
}
/**
 * Hide login
 */
function hideLogin() {
  const loginModalEl = document.getElementById("login");

  isLoginActive.value = false;

  if (loginModalEl) {
    loginModalEl.removeEventListener("elementToggled", hideLogin);
  }
}
/**
 * Toggle favorite
 */
function toggleFavorite(): void {
  if (!epiProductCode.value) {
    return;
  }

  emit("toggleFavorite", epiProductCode.value);
}
/**
 * Mouse over color
 */
function mouseOverColor(color) {
  hoverColor.value = color;
}
/**
 * Mouse out color
 */
function mouseOutColor() {
  hoverColor.value = null;
}

function getFallbackImage(productImage: ProductImage): string {
  return productImage.sources[productImage.sources.length - 1].url;
}

function getImageSources(productImage: ProductImage): Array<{
  srcset: string;
  media: string;
  type: string;
}> {
  const sources = productImage.sources.slice(0, -1);

  return sources.map((source: ProductImageSource, i: number) => {
    const isLast = i === sources.length - 1;

    return {
      srcset: source.url,
      media: isLast ? "" : `(max-width: ${source.width * 2}px)`,
      type: "image/webp",
    };
  });
}
</script>
