<script lang="ts" setup>
import { computed, defineProps, watch, ref, onMounted } from "vue";
import { SkuDetails } from "./sizePicker.models";
import { useAddToCartStore } from "./stores/addToCartStore";
import Icon from "../../components/icon/icon.vue";
import { IconSizes, IconTypes } from "../../components/icon/icon.model";
import { formatSize } from "../../mixins/productSize.mixin";

const props = defineProps<{
  sizesMatrixJson: string;
  chooseSize: string;
  chooseWidth: string;
  chooseLength: string;
  sizeGuide: string;
  isSizeGuide: boolean;
  selectSizeMessage: string;
  selectWidthMessage: string;
  selectLengthMessage: string;
  skuParameterCode: string;
  inventoryNotificationTrigger: string;
}>();

// Parse and store sizesMatrix from JSON
const sizesMatrix = computed<SkuDetails[][]>(() => {
  if (!props.sizesMatrixJson) return [];
  try {
    return JSON.parse(props.sizesMatrixJson);
  } catch (error) {
    console.error("Failed to parse sizesMatrixJson:", error);
    return [];
  }
});

const suitLengths = computed(() => {
  if (!sizesMatrix.value) return [];
  let lengths = new Set<string>();

  sizesMatrix.value?.forEach((skuDetailsArray) => {
    skuDetailsArray.forEach((skuDetails) => {
      lengths.add(skuDetails.suitLength);
    });
  });

  return Array.from(lengths);
});

const suitWidths = computed(() => {
  if (!sizesMatrix.value) return [];
  let widths = new Set<string>();

  sizesMatrix.value?.forEach((skuDetailsArray) => {
    skuDetailsArray.forEach((skuDetails) => {
      widths.add(skuDetails.suitWidth);
    });
  });

  return Array.from(widths);
});

const suitSizes = computed(() => {
  if (!sizesMatrix.value) return [];
  let sizes = new Set<string>();

  sizesMatrix.value?.forEach((skuDetailsArray) => {
    skuDetailsArray.forEach((skuDetails) => {
      sizes.add(skuDetails.suitSize);
    });
  });

  // Convert the Set to an array and sort numerically
  return Array.from(sizes).sort((a, b) => Number(a) - Number(b));
});

const addToCartStore = useAddToCartStore();

const displayWidthNotSelectedError = computed(
  () => addToCartStore.displayWidthNotSelectedError,
);

const displayLengthNotSelectedError = computed(
  () => addToCartStore.displayLengthNotSelectedError,
);

const displaySizeNotSelectedError = computed(
  () => addToCartStore.displaySizeNotSelectedError,
);

// Get in-stock suit lengths (different from available lengths)
const inStockSuitLengths = computed(() => {
  if (!sizesMatrix.value) return [];
  let inStockSuitLengthsSet = new Set<string>();

  sizesMatrix.value?.forEach((skuDetailsArray) => {
    skuDetailsArray.forEach((skuDetails) => {
      if (skuDetails.inventoryCount > 0) {
        inStockSuitLengthsSet.add(skuDetails.suitLength);
      }
    });
  });

  // Convert the Set to an array and sort numerically
  return Array.from(inStockSuitLengthsSet).sort(
    (a, b) => Number(a) - Number(b),
  );
});

// Get in-stock suit widths (different from available widths)
const inStockSuitWidths = computed(() => {
  if (!sizesMatrix.value) return [];
  let inStockSuitWidthsSet = new Set<string>();

  sizesMatrix.value?.forEach((skuDetailsArray) => {
    skuDetailsArray.forEach((skuDetails) => {
      if (skuDetails.inventoryCount > 0) {
        inStockSuitWidthsSet.add(skuDetails.suitWidth);
      }
    });
  });

  // Convert the Set to an array and sort numerically
  return Array.from(inStockSuitWidthsSet).sort((a, b) => Number(a) - Number(b));
});

// Get in-stock suit sizes (different from available sizes)
const inStockSuitSizes = computed(() => {
  if (!sizesMatrix.value) return [];
  let inStockSuitSizesSet = new Set<string>();

  sizesMatrix.value?.forEach((skuDetailsArray) => {
    skuDetailsArray.forEach((skuDetails) => {
      if (skuDetails.inventoryCount > 0) {
        inStockSuitSizesSet.add(skuDetails.suitSize);
      }
    });
  });

  // Convert the Set to an array and sort numerically
  return Array.from(inStockSuitSizesSet).sort((a, b) => Number(a) - Number(b));
});

const soldOutSizes = computed<SkuDetails[]>(() => {
  return sizesMatrix.value
    .flat()
    .filter((skuDetails) => skuDetails.inventoryCount === 0);
});

// Function to get SkuCode for a given width, length, and size
function getSkuCode(width: string, length: string, size: string) {
  let skuCode: string | undefined = undefined;

  sizesMatrix.value?.forEach((skuDetailsArray) => {
    skuDetailsArray.forEach((skuDetails) => {
      if (
        skuDetails.suitWidth === width &&
        skuDetails.suitLength === length &&
        skuDetails.suitSize === size
      ) {
        skuCode = skuDetails.skuCode;
      }
    });
  });

  return skuCode || "";
}

function getInventoryCount(width: string, length: string, size: string) {
  let inventoryCount = "0";

  sizesMatrix.value?.forEach((skuDetailsArray) => {
    skuDetailsArray.forEach((skuDetails) => {
      if (
        skuDetails.suitWidth === width &&
        skuDetails.suitLength === length &&
        skuDetails.suitSize === size
      ) {
        inventoryCount = skuDetails.inventoryCount.toString();
      }
    });
  });

  return inventoryCount;
}

// Function to get available sizes for a given width and length
function getAvailableSizes(suitWidth: string, suitLength: string) {
  const availableSizes = new Set<string>();

  sizesMatrix.value?.forEach((skuDetailsArray) => {
    skuDetailsArray.forEach((skuDetails) => {
      if (
        skuDetails.suitWidth === suitWidth &&
        skuDetails.suitLength === suitLength &&
        skuDetails.inventoryCount > 0 // Check inventory count
      ) {
        availableSizes.add(skuDetails.suitSize);
      }
    });
  });

  return Array.from(availableSizes);
}

// Function to get available lengths for a given width and size
function getAvailableLengths(suitWidth: string, suitSize: string) {
  const availableLengths = new Set<string>();

  sizesMatrix.value?.forEach((skuDetailsArray) => {
    skuDetailsArray.forEach((skuDetails) => {
      if (
        skuDetails.suitWidth === suitWidth &&
        skuDetails.suitSize === suitSize &&
        skuDetails.inventoryCount > 0 // Check inventory count
      ) {
        availableLengths.add(skuDetails.suitLength);
      }
    });
  });

  return Array.from(availableLengths);
}

// Function to get available widths for a given length and size
function getAvailableWidths(suitLength: string, suitSize: string) {
  const availableWidths = new Set<string>();

  sizesMatrix.value?.forEach((skuDetailsArray) => {
    skuDetailsArray.forEach((skuDetails) => {
      if (
        skuDetails.suitLength === suitLength &&
        skuDetails.suitSize === suitSize &&
        skuDetails.inventoryCount > 0 // Check inventory count
      ) {
        availableWidths.add(skuDetails.suitWidth);
      }
    });
  });

  return Array.from(availableWidths);
}

// Function to get available lengths and widths for a given suit size
function getAvailableLengthsAndWidths(suitSize: string) {
  const availableLengths = new Set<string>();
  const availableWidths = new Set<string>();

  sizesMatrix.value?.forEach((skuDetailsArray) => {
    skuDetailsArray.forEach((skuDetails) => {
      if (
        skuDetails.suitSize === suitSize &&
        skuDetails.inventoryCount > 0 // Check inventory count
      ) {
        availableLengths.add(skuDetails.suitLength);
        availableWidths.add(skuDetails.suitWidth);
      }
    });
  });

  return {
    availableLengths: Array.from(availableLengths),
    availableWidths: Array.from(availableWidths),
  };
}

// Function to get available lengths and sizes for a given suit width
function getAvailableLengthsAndSizes(suitWidth: string) {
  const availableLengths = new Set<string>();
  const availableSizes = new Set<string>();

  sizesMatrix.value?.forEach((skuDetailsArray) => {
    skuDetailsArray.forEach((skuDetails) => {
      if (
        skuDetails.suitWidth === suitWidth &&
        skuDetails.inventoryCount > 0 // Check inventory count
      ) {
        availableLengths.add(skuDetails.suitLength);
        availableSizes.add(skuDetails.suitSize);
      }
    });
  });

  return {
    availableLengths: Array.from(availableLengths),
    availableSizes: Array.from(availableSizes),
  };
}

// Function to get available widths and sizes for a given suit length
function getAvailableWidthsAndSizes(suitLength: string) {
  const availableWidths = new Set<string>();
  const availableSizes = new Set<string>();

  sizesMatrix.value?.forEach((skuDetailsArray) => {
    skuDetailsArray.forEach((skuDetails) => {
      if (
        skuDetails.suitLength === suitLength &&
        skuDetails.inventoryCount > 0 // Check inventory count
      ) {
        availableWidths.add(skuDetails.suitWidth);
        availableSizes.add(skuDetails.suitSize);
      }
    });
  });

  return {
    availableWidths: Array.from(availableWidths),
    availableSizes: Array.from(availableSizes),
  };
}

function translateWidth(width: string) {
  switch (width) {
    case "B":
      return "Normal";
    case "C":
      return "Ekstra";
    default:
      return width;
  }
}

function translateLength(length: string) {
  switch (length) {
    case "1":
      return "Kort";
    case "2":
      return "Normal";
    case "3":
      return "Lang";
    default:
      return length;
  }
}

// Reactive state for selected values
const selectedSuitSize = ref<string>("");
const selectedSuitWidth = ref<string>("");
const selectedSuitLength = ref<string>("");

// Reactive available options based on selected values
const availableSizes = ref<string[]>(suitSizes.value);
const availableWidths = ref<string[]>(suitWidths.value);
const availableLengths = ref<string[]>(suitLengths.value);

// Function to automatically select a single in-stock option if only one exists
const autoSelectSingleOption = () => {
  if (inStockSuitSizes.value.length === 1) {
    selectedSuitSize.value = inStockSuitSizes.value[0];
    addToCartStore.setSizeSelected(true);
  }
  if (inStockSuitWidths.value.length === 1) {
    selectedSuitWidth.value = inStockSuitWidths.value[0];
    addToCartStore.setWidthSelected(true);
  }
  if (inStockSuitLengths.value.length === 1) {
    selectedSuitLength.value = inStockSuitLengths.value[0];
    addToCartStore.setLengthSelected(true);
  }

  if (
    selectedSuitSize.value &&
    selectedSuitWidth.value &&
    selectedSuitLength.value
  ) {
    addToCartStore.setSkuCode(
      getSkuCode(
        selectedSuitWidth.value,
        selectedSuitLength.value,
        selectedSuitSize.value,
      ),
    );
    addToCartStore.setInventoryCount(
      getInventoryCount(
        selectedSuitWidth.value,
        selectedSuitLength.value,
        selectedSuitSize.value,
      ),
    );
    addToCartStore.setCheckInventoryStatus(true);
  }
};

// Call the function when the component is mounted
onMounted(() => {
  autoSelectSingleOption();
});

watch(
  [selectedSuitSize, selectedSuitWidth, selectedSuitLength],
  ([newSize, newWidth, newLength]) => {
    if (newSize && newWidth && newLength) {
      addToCartStore.setSkuCode(getSkuCode(newWidth, newLength, newSize));
      addToCartStore.setInventoryCount(
        getInventoryCount(newWidth, newLength, newSize),
      );
      addToCartStore.setCheckInventoryStatus(true);
    }
  },
);

// Watchers to update available options based on selections
watch(
  [selectedSuitSize, selectedSuitWidth, selectedSuitLength],
  () => {
    let tempAvailableWidths = suitWidths.value;
    let tempAvailableLengths = suitLengths.value;
    let tempAvailableSizes = suitSizes.value;

    if (selectedSuitSize.value) {
      const { availableWidths, availableLengths } =
        getAvailableLengthsAndWidths(selectedSuitSize.value);
      tempAvailableWidths = availableWidths;
      tempAvailableLengths = availableLengths;
    }

    if (selectedSuitWidth.value) {
      const { availableLengths, availableSizes } = getAvailableLengthsAndSizes(
        selectedSuitWidth.value,
      );
      tempAvailableLengths = tempAvailableLengths.filter((length) =>
        availableLengths.includes(length),
      );
      tempAvailableSizes = availableSizes;
    }

    if (selectedSuitLength.value) {
      const { availableWidths, availableSizes } = getAvailableWidthsAndSizes(
        selectedSuitLength.value,
      );
      tempAvailableWidths = tempAvailableWidths.filter((width) =>
        availableWidths.includes(width),
      );
      tempAvailableSizes = tempAvailableSizes.filter((size) =>
        availableSizes.includes(size),
      );
    }

    // When two properties are selected, filter the third
    if (selectedSuitSize.value && selectedSuitWidth.value) {
      tempAvailableLengths = getAvailableLengths(
        selectedSuitWidth.value,
        selectedSuitSize.value,
      );
    }

    if (selectedSuitSize.value && selectedSuitLength.value) {
      tempAvailableWidths = getAvailableWidths(
        selectedSuitLength.value,
        selectedSuitSize.value,
      );
    }

    if (selectedSuitWidth.value && selectedSuitLength.value) {
      tempAvailableSizes = getAvailableSizes(
        selectedSuitWidth.value,
        selectedSuitLength.value,
      );
    }

    availableWidths.value = tempAvailableWidths;
    availableLengths.value = tempAvailableLengths;
    availableSizes.value = tempAvailableSizes;
  },
  { immediate: true },
);

// Watchers to log when sizes change (can be used for debugging)
watch(
  () => sizesMatrix.value,
  (newVal) => {
    console.log("New sizesMatrix:", newVal);
  },
);

watch(
  () => suitWidths.value,
  (newVal) => {
    console.log("New suit widths:", newVal);
  },
);

watch(
  () => suitLengths.value,
  (newVal) => {
    console.log("New suit lengths:", newVal);
  },
);

watch(
  () => suitSizes.value,
  (newVal) => {
    console.log("New suit sizes:", newVal);
  },
);

// Watchers to update the skuCode when all three properties are selected
watch(
  [selectedSuitWidth, selectedSuitLength, selectedSuitSize],
  ([width, length, size]) => {
    if (width && length && size) {
      addToCartStore.setSkuCode(getSkuCode(width, length, size));
    }
  },
  { immediate: true },
);

// Create a watcher for soldOutSizes
watch(soldOutSizes, (newSoldOutSizes, oldSoldOutSizes) => {
  if (newSoldOutSizes.length !== oldSoldOutSizes.length) {
    console.log("Sold out sizes have changed:", newSoldOutSizes);
  }
});
</script>

<template>
  <div class="sizepicker sizepicker--suit">
    <!-- Widths Section -->
    <div class="size-tile-group">
      <!-- Width Information -->
      <div class="product__size-information">
        <span id="selectedsize" class="size-tile-group__group--title">
          <span v-if="!selectedSuitWidth">{{ chooseWidth }}:</span>
          <!-- Conditionally render the selected width when selected -->
          <span v-if="selectedSuitWidth">
            {{ chooseWidth }}:
            <strong>{{ translateWidth(selectedSuitWidth) }}</strong>
          </span>
        </span>
        <!-- Size Guide Button (Conditional) -->
        <span v-if="isSizeGuide" class="product__size-guide">
          <button
            type="button"
            class="product__size-guide btn btn--small btn--link js-btn _js-vue-component-toggler"
            data-togglewhat="sizeguide"
          >
            {{ sizeGuide }}
          </button>
        </span>
      </div>

      <!-- Widths -->
      <!-- If width, size, and length are all selected, 
        the input value gets populated with the skuCode -->
      <div class="size-tile-group__items size-tile-group__items--width">
        <div
          v-for="width in suitWidths"
          :key="width"
          class="size-tile-group__item size-tile-group__item"
        >
          <input
            :id="'width_' + width"
            v-model="selectedSuitWidth"
            :name="skuParameterCode"
            :value="width"
            type="radio"
            :data-size-name="
              selectedSuitWidth && selectedSuitLength && selectedSuitSize
                ? formatSize(
                    selectedSuitWidth + selectedSuitLength + selectedSuitSize,
                  )
                : ''
            "
            :data-sku="
              selectedSuitWidth && selectedSuitLength && selectedSuitSize
                ? getSkuCode(
                    selectedSuitWidth,
                    selectedSuitLength,
                    selectedSuitSize,
                  )
                : ''
            "
            class="size-tile-group__input _js-check-stock _jsSizeSelector"
            :disabled="
              !availableWidths.includes(width) ||
              !inStockSuitWidths.includes(width)
            "
            hidden
            @change="addToCartStore.setWidthSelected(true)"
          />
          <label
            :for="'width_' + width"
            :class="[
              'size-tile-group__label',
              {
                'size-tile-group__label--soldout _js-inventory-soldout':
                  !availableWidths.includes(width) ||
                  !inStockSuitWidths.includes(width),
              },
            ]"
          >
            <span class="size-tile-group__text">{{
              translateWidth(width)
            }}</span>
          </label>
        </div>
      </div>
      <!-- Error Message if width not selected -->
      <span v-if="displayWidthNotSelectedError" class="size-tile-group__error">
        {{ selectWidthMessage }}
      </span>
    </div>

    <!-- Lengths Section -->
    <div class="size-tile-group">
      <!-- Length Information -->
      <div class="product__size-information">
        <span id="selectedsize" class="size-tile-group__group--title">
          <span v-if="!selectedSuitLength">{{ chooseLength }}:</span>
          <!-- Conditionally render the selected length when selected -->
          <span v-if="selectedSuitLength">
            {{ chooseLength }}:
            <strong>{{ translateLength(selectedSuitLength) }}</strong>
          </span>
        </span>
      </div>

      <!-- Lengths -->
      <div class="size-tile-group__items size-tile-group__items--length">
        <div
          v-for="length in suitLengths"
          :key="length"
          class="size-tile-group__item size-tile-group__item"
        >
          <input
            :id="'length_' + length"
            v-model="selectedSuitLength"
            type="radio"
            :value="length"
            class="size-tile-group__input"
            :disabled="
              !availableLengths.includes(length) ||
              !inStockSuitLengths.includes(length)
            "
            hidden
            @change="addToCartStore.setLengthSelected(true)"
          />
          <label
            :for="'length_' + length"
            :class="[
              'size-tile-group__label',
              {
                'size-tile-group__label--soldout _js-inventory-soldout':
                  !availableLengths.includes(length) ||
                  !inStockSuitLengths.includes(length),
              },
            ]"
          >
            <span class="size-tile-group__text">{{
              translateLength(length)
            }}</span>
          </label>
        </div>
      </div>
      <!-- Error Message if length not selected -->
      <span v-if="displayLengthNotSelectedError" class="size-tile-group__error">
        {{ selectLengthMessage }}
      </span>
    </div>

    <!-- Sizes Section -->
    <div class="size-tile-group">
      <!-- Size Information -->
      <div class="product__size-information">
        <span id="selectedsize" class="size-tile-group__group--title">
          <span v-if="!selectedSuitSize">{{ chooseSize }}:</span>
          <!-- Conditionally render the selected size when selected -->
          <span v-if="selectedSuitSize">
            {{ chooseSize }}:
            <strong>{{ selectedSuitSize }}</strong>
          </span>
        </span>
      </div>

      <!-- Sizes -->
      <div class="size-tile-group__items size-tile-group__items">
        <div
          v-for="size in suitSizes"
          :key="size"
          class="size-tile-group__item size-tile-group__item--normal"
        >
          <input
            :id="'size_' + size"
            v-model="selectedSuitSize"
            type="radio"
            :value="size"
            class="size-tile-group__input"
            :disabled="
              !availableSizes.includes(size) || !inStockSuitSizes.includes(size)
            "
            hidden
            @change="addToCartStore.setSizeSelected(true)"
          />
          <label
            :for="'size_' + size"
            :class="[
              'size-tile-group__label',
              {
                'size-tile-group__label--soldout _js-inventory-soldout':
                  !availableSizes.includes(size) ||
                  !inStockSuitSizes.includes(size),
              },
            ]"
          >
            <span class="size-tile-group__text">{{ size }}</span>
          </label>
        </div>
      </div>
      <!-- Error Message if size not selected -->
      <span v-if="displaySizeNotSelectedError" class="size-tile-group__error">
        {{ selectSizeMessage }}
      </span>
    </div>
    <!-- Inventory Low Notification -->
    <span v-if="addToCartStore.inventoryIsLow" class="size-tile-group__warning">
      {{ addToCartStore.inventoryLowMessage }}
    </span>
    <div
      v-if="soldOutSizes.length > 0"
      class="product__inventory-notification _js-vue-inventory-nofitication-btn-container"
    >
      <button
        class="product__inventory-notification btn--link _js-vue-component-toggler _js-vue-inventory-notification-btn"
        data-togglewhat="inventoryNotification"
      >
        <Icon :type="IconTypes.Bell" :size="IconSizes.Tiny" />
        <p>
          {{ inventoryNotificationTrigger }}
        </p>
      </button>
    </div>
  </div>
</template>
